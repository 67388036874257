import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import Layout from "~/components/layout";
//import PageHeading from "~/components/styled/page-heading";
import TourList from "~/components/tour-list";
import { StaticImage } from "gatsby-plugin-image";
//import stars from "~/images/stars-4.5.svg";
//import tustbadge from "~/images/trust-badge.png";
import Image from "~/components/image";
import { Link } from "gatsby";
import ReviewsWidget from "~/components/ReviewsWidget";
//import { StaticImage } from "gatsby-plugin-image";
//import Moment from "react-moment";
import {
	ChevronRightIcon,
	CheckIcon,
	ArrowRightIcon,
} from "@heroicons/react/outline";

const DestinationPage = ({ data }) => {
	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);

	const dest = data.strapiDestination;

	// Define UTM parameters
	const readGuideUTM = new URLSearchParams({
		utm_source: "landing",
		utm_medium: "cta-button",
		utm_campaign: "read-guide",
	}).toString();

	const designTripUTM = new URLSearchParams({
		utm_source: "landing",
		utm_medium: "cta-button",
		utm_campaign: "design-my-trip",
	}).toString();

	const designTripWithUTM = `/design-trip/?${designTripUTM}`;

	const contactTripUTM = new URLSearchParams({
		utm_source: "landing",
		utm_medium: "cta-button",
		utm_campaign: "contact-us",
	}).toString();

	const contactTripWithUTM = `/contact-us/?${contactTripUTM}`;

	// Array of all reviews in desc order
	//const allNews = dest.reviews.sort((a, b) => (a.date > b.date ? -1 : 1));

	// state for the read more
	//const [expanded, setExpanded] = useState(false);

	// const handleReadMore = () => {
	// 	setExpanded(!expanded);
	// };

	// State for the list
	//const [list, setList] = useState([...allNews.slice(0, 3)]);

	// State to trigger oad more
	//const [loadMore, setLoadMore] = useState(false);

	// State of whether there is more to load
	//const [hasMore, setHasMore] = useState(allNews.length > 3);

	// Load more button click
	// const handleLoadMore = () => {
	// 	setLoadMore(true);
	// };

	// Handle loading more articles
	// useEffect(() => {
	// 	if (loadMore && hasMore) {
	// 		const currentLength = list.length;
	// 		const isMore = currentLength < allNews.length;
	// 		const nextResults = isMore
	// 			? allNews.slice(currentLength, currentLength + 3)
	// 			: [];
	// 		setList([...list, ...nextResults]);
	// 		setLoadMore(false);
	// 	}
	// }, [loadMore, hasMore]);

	//eslint-disable-line

	//Check if there is more
	// useEffect(() => {
	// 	const isMore = list.length < allNews.length;
	// 	setHasMore(isMore);
	// }, [list]);

	//eslint-disable-line

	// const seo = {
	//   title: data.strapiDestination.name,
	// }
	//const description = `Discover the best of ${data.strapiDestination.name} on a bicycle tour.`
	// const language={
	//     href: `https://www.artofbicycletrips.com/destinations/${data.strapiDestination.name}`,
	//   }

	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title={`Best ${data.strapiDestination.name} Bike Tours & E-Bike Tours | Cycling Tours in ${data.strapiDestination.name}`}
				description={`Discover the best of ${data.strapiDestination.name} by bike with Art of Bicycle Trips. Curated routes, beautiful hotels, all-inclusive tours, expert guides. Book Now!`}
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/destinations/${data.strapiDestination.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/destinations/${data.strapiDestination.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.in/destinations/${data.strapiDestination.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/destinations/${data.strapiDestination.slug}`,
				// 	},
				// ]}
			/>
			<BreadcrumbJsonLd
				itemListElements={[
					{
						position: 1,
						name: "Destinations",
						item: "https://artofbicycletrips.com/destinations",
					},
					{
						position: 2,
						name: `${data.strapiDestination.name} Bicycle Tours`,
					},
				]}
			/>
			{/* <ProductJsonLd
				productName={data.strapiDestination.name}
				
				description={`From North to South, traverse the best of ${data.strapiDestination.name} by bike.`}
				brand="Art of Bicycle Trips"
				aggregateRating={{
					ratingValue: "5",
					reviewCount: `${allNews.length}`,
				}}
				mpn={data.strapiDestination.name}
			/> */}
			{/* like Hero  */}
			<section className="relative bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="flex flex-col lg:flex-row items-center">
					{/* Left Half */}
					<div className="lg:w-1/2 py-8 px-8 lg:px-0 lg:pr-20 text-center lg:text-left">
						<div className="flex flex-col items-center lg:items-start">
							<h1 className="text-2xl text-black font-normal sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl">
								{/* <span className="font-light   ">Premier</span>{" "} */}
								<span className="text-black font-bold">
									{" "}
									Thoughtfully Crafted {data.strapiDestination.name} Bike Tours and
									E-Bike Tours
								</span>
							</h1>
							<p className="mt-3 text-base sm:text-lg mb-10 text-gray-600 ">
								Featuring boutique hotels and expert local guides.
							</p>
							{/* <p className="mt-3 text-base sm:text-lg mb-10 text-gray-600 ">
								Boutique and personalized cycling & e-bike tours in{" "}
								{data.strapiDestination.name} for every journey.
							</p> */}
							{data.strapiDestination.ebike == true && (
								<div className="mb-10 ">
									<CheckIcon className="h-6 w-6 bg-primary2 text-white rounded-full p-1 inline-flex mr-2" />
									<span className="text-base text-primary2 font-medium ">
										E-bikes Available
									</span>
								</div>
							)}
							<div className="flex flex-col w-full md:w-auto md:flex-row  md:space-x-4">
								<a
									href="#explore"
									className="bg-primary capitalize mb-4 md:mb-0 tracking-wide font-semibold text-white px-4 py-3 md:py-4 md:px-8 lg:px-5 xl:px-8 rounded-full"
								>
									Explore trips
								</a>
								<Link
									to={designTripWithUTM}
									className="bg-white capitalize border font-semibold tracking-wide  border-primary text-primary px-4 py-3 md:py-4 md:px-6 lg:px-4 xl:px-6 rounded-full"
								>
									Design private trip
								</Link>
							</div>
						</div>
					</div>

					{/* Right Half */}
					<div className="lg:w-1/2 mt-2 lg:mt-0">
						<Image
							className="rounded-lg shadow-md transform border border-gray-200"
							image={data.strapiDestination.image}
							alt={
								data.strapiDestination.image.name.split("-").join(" ").split(".")[0]
							}
							loading="lazy"
						/>
					</div>
				</div>
			</section>
			{/* <section className="relative lg:pb-28 xl:pb-20 bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="max-w-7xl mx-auto">
					<div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
						<div className="mt-10 mx-auto lg:mx-0 max-w-xl sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
							<div className="sm:text-center  lg:text-left">
								<h1 className=" text-gray-900 lg:text-3xl  xl:text-4xl">
									<span className="block   xl:inline font-normal">Premier</span>{" "}
									<span className="block text-primary  xl:inline font-normal">
										{data.strapiDestination.name} Bike Tours and Cycling Holidays
									</span>{" "}
								</h1>

								<p className="mt-3 mb-10 leading-relaxed sm:mt-5 sm:max-w-md sm:mx-auto md:mt-5 lg:mx-0">
									We're all about small and local details.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="lg:absolute lg:pl-8 lg:inset-y-0 lg:right-0 lg:w-1/2 ">
					<div className="relative">
						<Image
							className="rounded-md shadow-md transform border border-gray-200"
							image={data.strapiDestination.image}
							alt={
								data.strapiDestination.image.name.split("-").join(" ").split(".")[0]
							}
							loading="lazy"
						/>
					</div>
				</div>
			</section> */}
			{/* banner image */}
			{/* {data.strapiDestination.image && (
				<div className="">
					<Image
						className="rounded-lg shadow-md transform"
						image={data.strapiDestination.image}
						alt={data.strapiDestination.image.name.split("-").join(" ").split(".")[0]}
						loading="lazy"
					/>
				</div>
			)} */}

			{/* Breadcrumbs */}
			<div className="my-4 flex items-center capitalize whitespace-nowrap overflow-x-auto">
				<Link className="text-xs" to={`/`}>
					Home
				</Link>
				<ChevronRightIcon className="h-4 w-4 text-gray-300 mx-1" />
				<Link className="text-xs" to={`/destinations`}>
					Destinations
				</Link>
				<ChevronRightIcon className="h-4 w-4 text-gray-300 mx-1" />
				<span className="text-xs">{data.strapiDestination.name}</span>
			</div>
			<section>
				<div className="mt-20">
					{/* trustpilot */}
					<div className="text-center my-10">
						<a
							href="https://www.trustpilot.com/review/artofbicycletrips.com"
							target="_blank"
							rel="noopener noreferrer"
							className="border border-gray-300 p-4 inline-block mx-auto"
						>
							<div className="text-center sm:flex sm:justify-center">
								<div className="flex flex-row justify-center items-center">
									<p className="text-base sm:text-lg font-semibold">Excellent</p>
									{/* <img
										src={stars}
										alt="Trustpilot Logo"
										className="w-24 h-auto sm:w-28 sm:h-auto ml-4 inline-block"
									/> */}
									<StaticImage
										className="w-24 h-auto sm:w-28 sm:h-auto ml-4 inline-block"
										src="../../images/stars-4.5.svg"
										alt="Trustpilot Stars"
										loading="lazy"
									/>
								</div>
								<div className="flex flex-row mt-1 sm:mt-0 justify-center items-center">
									<p className="text-sm sm:text-sm font-normal sm:ml-3">4.6 out of 5</p>
									{/* <img
										src={tustbadge}
										alt="Trustpilot Logo"
										className="w-auto h-6 sm:w-auto sm:h-6 ml-2 inline-block"
									/> */}
									<StaticImage
										className="w-24 h-auto sm:w-28 sm:h-auto ml-2 inline-block"
										src="../../images/trust-badge.png"
										alt="Trustpilot Logo"
										loading="lazy"
									/>
								</div>
							</div>
						</a>
					</div>
					{/* <h2 className="text-center mb-10">
						The Best {data.strapiDestination.name} Bicycle Tours
					</h2> */}
					{/* trustpilot */}

					{/* <div className="text-center">
					<PageHeading>
						Premier {data.strapiDestination.name} Bike Tours and Cycling Holidays
					</PageHeading>
				</div> */}
					{/* <p className="leading-loose mb-16 font-light md:text-xl md:ml-32 md:mx-16 lg:mx-36">
					{data.strapiDestination.desc}
				</p> */}
					{/* Read More for description */}
					{/* {data.strapiDestination.detail && (
						<>
							{expanded ? (
								<div className="mb-20">
									<ReactMarkdown
										className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mb-6 mx-auto leading-loose font-light"
										children={data.strapiDestination.detail}
									/>
									<div className="text-center">
										<button
											className="text-primary inline-block"
											onClick={handleReadMore}
										>
											Read less <ChevronUpIcon className="h-4 w-4 inline-flex mr-2" />
										</button>
									</div>
								</div>
							) : (
								<div className="mb-20">
									<ReactMarkdown
										className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mb-6 mx-auto leading-loose font-light"
										children={`${data.strapiDestination.detail.substring(0, 600)}...`}
									/>
									
									<div className="text-center">
										<button
											className="text-primary inline-block"
											onClick={handleReadMore}
										>
											Read more <ChevronDownIcon className="h-4 w-4 inline-flex mr-2" />
										</button>
									</div>
								</div>
							)}
						</>
					)} */}
					{/* {data.strapiDestination.detail && (
					<ReactMarkdown
						className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mb-20 mx-auto leading-loose font-light"
						children={data.strapiDestination.detail}
					/>
				)} */}
				</div>
				{/* Region List Start */}
				<div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize py-10">
					{dest.regions &&
						dest.regions.map((reg) => {
							return (
								<div key={reg.id}>
									<Link to={`/destinations/${reg.slug}/`} key={reg.id}>
										<div className="">
											<span className="border-2 font-medium border-primary p-4 md:p-6 rounded-lg">
												{reg.name}
											</span>
										</div>
									</Link>
								</div>
							);
						})}
				</div>
			</section>
			{/* Region List End */}
			{/* <!-- List of Trips Section --> */}
			<section id="explore" className="mt-10">
				<div className="bg-landing py-20 px-4 md:px-8 lg:px-10 xl:px-12 rounded-2xl">
					<h2 className="mb-10">
						Explore Our Small Group Cycling Tours and Holidays in{" "}
						{data.strapiDestination.name}
					</h2>
					<TourList tours={flatTours} />
				</div>
				{/* <div className="flex flex-col bg-primary rounded-md px-6 py-14 md:px-20 md:py-20 lg:flex-row lg:justify-between items-center my-6">
					<div className="max-w-xl text-center lg:text-left">
						
						<p className=" text-white tracking-wide font-semibold uppercase md:text-xl lg:text-2xl">
							Silver Riders Club Membership
						</p>
						<p className=" text-white">
							Exclusive Members-only rides in breathtaking off-beat destinations at
							special prices.
						</p>
					</div>
					<div className="mt-10 lg:mt-0">
						<button className="border border-white px-8 py-3 md:py-4 md:px-8 rounded-full ">
							<Link to="/members-only" className="text-white md:text-lg">
								Join the Community
							</Link>
						</button>
					</div>
				</div> */}
				{/* <div className="flex flex-col bg-primary rounded-md px-6 py-14 md:px-20 md:py-20 lg:flex-row lg:justify-between items-center my-6">
					<div className="max-w-xl text-center lg:text-left">
						<p className="md:text-xl lg:text-2xl text-white mb-6">
							Couldn't find a trip that matches your interest?
						</p>
						<p className=" text-white font-semibold uppercase md:text-xl lg:text-2xl">
							Design Your Own Adventure
						</p>
						<p className="text-xs font-light text-white md:text-sm">
							100% tailor-made based on your travel interests
						</p>
					</div>
					<div className="mt-10 lg:mt-0">
						<button className="border border-white px-8 py-3 md:py-4 md:px-8 rounded-full ">
							<Link to="/design-trip" className="text-white md:text-lg">
								Design trip
							</Link>
						</button>
					</div>
				</div> */}
			</section>

			{/* reviews */}
			<section id="reviews" className="my-16 md:my-28 overflow-hidden">
				<h2 className="mb-10 uppercase">Reviews from our friends</h2>
				{data.strapiDestination.sku !== null && (
					<div>
						<ReviewsWidget sku={data.strapiDestination.sku} />
					</div>
				)}
				{/* <div className="text-center py-8">
								<a
									href="https://www.trustpilot.com/review/artofbicycletrips.com"
									target="_blank"
									rel="noopener noreferrer"
									className=""
								>
									<div className="text-center md:flex md:justify-center">
										<div className="flex flex-row justify-center items-center">
											<p className="text-base md:text-lg font-semibold">Excellent</p>
											<img
												src={stars}
												alt="Trustpilot Logo"
												className="w-24 h-auto md:w-28 md:h-auto ml-4 inline-block"
											/>
										</div>
										<div className="flex flex-row mt-1 md:mt-0 justify-center items-center">
											<p className="text-sm md:text-sm font-normal md:ml-3">
												Based on {allNews.length} reviews on
											</p>
											<img
												src={tustbadge}
												alt="Trustpilot Logo"
												className="w-auto h-6 md:w-auto md:h-6 ml-2 inline-block"
											/>
										</div>
									</div>
								</a>
							</div> */}

				{/* Load more start*/}

				{/* <div>
					{allNews &&
						list.map((reviews) => {
							return (
								<div key={reviews.id} className="container px-4 mx-auto">
									<div className="mb-4 shadow-lg rounded-2xl overflow-hidden">
										<div className="pt-3 pb-3 md:pb-3 px-4 md:px-16 bg-bgtrust">
											<div className="flex flex-wrap items-center">
												<h4 className="w-full text-trust md:w-auto font-medium">
													{reviews.name} from {reviews.location}
												</h4>
												<div className="w-full md:w-px h-2 md:h-8 mx-8 bg-transparent md:bg-gray-200"></div>
												<span className="mr-4 text-xl text-trust font-heading font-medium">
													5.0
												</span>
												<div className="inline-flex">
													<span className="inline-block mr-1">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
													<span className="inline-block mr-1">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
													<span className="inline-block mr-1">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
													<span className="inline-block mr-1">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
													<span className="inline-block text-gray-200">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
												</div>
											</div>
										</div>
										<div className="px-4 overflow-hidden md:px-16 pt-8 pb-8 bg-white">
											<div className="flex flex-wrap">
												<div className="w-full md:w-2/3 mb-6 md:mb-0">
													<p className="font-semibold mb-2 text-lg capitalize">
														{reviews.title}
													</p>
													<p className="prose prose-sm max-w-2xl leading-loose">
														{reviews.detailed}
													</p>
													
												</div>
												<div className="w-full md:w-1/3 text-right">
													<Moment
														className="mb-auto text-sm text-gray-400"
														format="MMM Do YYYY"
													>
														{reviews.date}
													</Moment>
												</div>
											</div>
										</div>
									</div>

									
								</div>
							);
						})}
				</div>
				<div className="text-center">
					{hasMore ? (
						<button
							onClick={handleLoadMore}
							className=" w-auto h-full py-3 px-6 md:py-4 md:px-8 font-medium text-white bg-trust rounded-full"
						>
							View more
						</button>
					) : (
						<p className="text-white uppercase">End of reviews</p>
					)}
				</div> */}
				{/* Load more end */}
			</section>
			{/* Detail Section */}
			<section className="xl:w-5/6">
				{data.strapiDestination.section.map((node) => {
					return (
						<div key={node.id} id={node.id}>
							<div className="m-auto ">
								<h2 className="mt-10 lg:mt-10 mb-10">{node.heading}</h2>
								<ReactMarkdown
									className="prose prose-sm max-w-full mb-20"
									children={node.detail}
								/>
							</div>
						</div>
					);
				})}
			</section>
			{data.strapiDestination.section.length > 0 && (
				<div className="flex flex-col w-5/6 mx-auto text-center md:w-auto md:flex-row  md:space-x-4">
					<a
						href="#explore"
						className="bg-primary mb-4 md:mb-0 capitalize tracking-wide font-medium text-white px-4 py-3 md:py-4 md:px-8 rounded-full"
					>
						Explore {data.strapiDestination.name} trips
					</a>
					{/* <Link
						to={designTripWithUTM}
						className="bg-white capitalize border font-medium tracking-wide  border-primary text-primary px-4 py-3 md:py-4 md:px-6 rounded-full"
					>
						Design a private trip
					</Link> */}
				</div>
			)}

			{/* CTA */}
			<section className="mt-28">
				<div className="flex flex-col bg-landing rounded-lg px-6 py-10 mb-20 md:px-12 md:py-20 lg:flex-row lg:justify-between items-center">
					<div className="max-w-xl text-center lg:text-left">
						<h3 className="tracking-wide font-semibold text-black uppercase text-2xl md:text-3xl">
							Let's Start Planning!
						</h3>
						<p className=" mt-2 text-lg md:text-xl">
							Contact us today to book your {data.strapiDestination.name} bike tour or
							inquire about custom options.
						</p>
					</div>
					<div className="mt-10 lg:mt-10 xl:mt-0">
						<button className="">
							<Link
								to={contactTripWithUTM}
								className="text-white font-medium tracking-wide bg-articlecta px-6 py-3 md:py-4 md:px-6 rounded-full "
							>
								Contact us <ArrowRightIcon className="h-4 w-4 inline-flex" />
							</Link>
						</button>
					</div>
				</div>
			</section>
		</Layout>
	);
};

// queries

// slider {
// 	id
// 	url
// 	name
// 	alternativeText
// 	localFile {
// 		childImageSharp {
// 			gatsbyImageData(aspectRatio: 1.3)
// 		}
// 	}
// }

export const query = graphql`
	query DestinationQuery($slug: String!) {
		allStrapiTour(
			filter: { destination: { slug: { eq: $slug } } }
			sort: { fields: price, order: DESC }
		) {
			edges {
				node {
					duration
					price
					slug
					title
					popular
					new
					tourType
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}

					quickfacts {
						lodging
						level
					}
					activity {
						name
					}

					regions {
						name
					}
					destination {
						name
					}
				}
			}
		}

		strapiDestination(slug: { eq: $slug }, locale: { eq: "en" }) {
			name
			desc
			slug
			detail
			ebike
			sku
			section {
				heading
				detail
			}
			reviews {
				name
				location
				date
				title
				detailed
			}
			regions {
				id
				name
				slug
			}
			image {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(aspectRatio: 1.2)
					}
				}
			}
		}
	}
`;

export default DestinationPage;
